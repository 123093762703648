import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8.js";
import Headings from "../components/Headings.js";

import { privacyHeroDetails } from "../constants/index.js";

import styles from "../styles/style.js";


const PrivacyPolicy = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: allFile(
        filter: { name: { in: "hero-brown-books-18th-edition" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 2.99
            )
          }
        }
      }
    }
  `);

  //console.log("Data - Sponsorship: ", data);

  const structuredData = (
    <script type="application/ld+json">
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Intact Electrical Ltd - Privacy policy",
        "url":  "https://intact-electrical.co.uk/privacy-policy",
        "logo": "https://intact-electrical.co.uk/static/0e28fe2974beb0df4a3655e8f2b63a68/ad11a/logo-icon.avif"
      })}
    </script>
  )

  const heroBackgroundImage =
    data.heroImage.nodes[0].childImageSharp.gatsbyImageData;

  const {
    buttonLeft,
    buttonLeftLink,
    buttonRight,
    buttonRightLink,
    excerpt,
    titleTop,
    titleBottom,
  } = privacyHeroDetails;

  return (
    <>
      <Seo       
        title="Privacy policy"
        description="Data protection, Privacy policy, How we collect your personal data, What we collect, Sharing your personal data, Know your rights"
        post={false}
        metaRobots="follow, index"
        structuredData={structuredData}
      />
      <HeroV8
        heroImage={heroBackgroundImage}
        heroTitleTop={titleTop}
        heroTitleBottom={titleBottom}
        excerpt={excerpt}
        btnLeft={buttonLeft}
        btnLeftLink={buttonLeftLink}
        btnRight={buttonRight}
        btnRightLink={buttonRightLink}
      />
      <div className={`bg-[#ffffff]  ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <section className="mt-6">
            <Headings
              titleTop="Data protection"
              titleBottom="for us all"
              description="This Privacy Policy was last updated in September 2023, Intact Electrical reserve the right to update or amend our privacy policy without prior notice."
            />
            <div className="mt-4 mb-4">
            
              <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]  leading-[18px] w-full pb-4 mt-8">Privacy policy</h2>

              <p className="text-base text-gray-700 md:text-lg">Intact Electrical Ltd is committed to protecting your privacy and the confidentiality of your personal information. 
              The policy describes what information we may collect about you; how we use your information; how we protect it; and what rights you have in relation to the personal data 
              we hold about you.</p>
              
              <p className="text-base text-gray-700 md:text-lg mt-4">
              When you provide personal data about yourself to us, you are deemed to have consented to our collection, use and/or disclosure of your personal data for the purposes set out 
              in this policy. The information that we process is that which is reasonably necessary in relation to your engagement with Intact Electrical Ltd.
              </p>

              <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]  leading-[18px] w-full pb-4 mt-8">How and when we collect your personal data</h2>

              <p className="text-base text-gray-700 md:text-lg">
              We may collect your personal data in a number of ways, as follows:
              </p>

              <ul className="list-disc text-base text-gray-700 md:text-lg mt-4 ml-6">
                <li>When you give us your personal data.</li>
                <li>During any interviews or meetings we may conduct with you.</li>
                <li>When we contact you by telephone, post, e-mail or through any other means.</li>
                <li>When you contact us through our website, by telephone, post, e-mail or through any other means.</li>
                <li>When we deal with any enquiries you make to us.</li>
                <li>When we collect publicly available information</li>
                <li>When you disclose your personal data to us or others by any other means.</li>
              </ul>

              <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]  leading-[18px] w-full pb-4 mt-8">What types of personal data do we collect?</h2>

              <p className="text-base text-gray-700 md:text-lg">
              We may collect some or all of the following personal, special category and non-personal data:
              </p>

              <ul className="list-disc text-base text-gray-700 md:text-lg mt-4 ml-6">
                <li>Your salutation and name.</li>
                <li>Your address, email address, telephone number and other contact information.</li>
                <li>Your business/company name, job title and profession.</li>
              </ul>

              <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]  leading-[18px] w-full pb-4 mt-8">Sharing your personal data</h2>

              <p className="text-base text-gray-700 md:text-lg">
              Your personal data may be shared with or accessed by:
              </p>

              <ul className="list-disc text-base text-gray-700 md:text-lg mt-4 ml-6">
                <li>Officers, representatives and directors of Intact Electrical Ltd</li>
                <li>Government bodies, regulatory authorities and law enforcement agencies where legally required</li>
                <li>Suppliers and agents who administer and maintain, amongst other activities, our website</li>
              </ul>

              <h2 className="font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px]  leading-[18px] w-full pb-4 mt-8">Know your rights</h2>

              <p className="text-base text-gray-700 md:text-lg">
              Your rights are as follows:
              </p>

              <ul className="list-disc text-base text-gray-700 md:text-lg mt-4 ml-6">
                <li>The right to be informed about our collection and use of the personal data you provide.</li>
                <li>The right of access to the personal data we hold about you.</li>
                <li>The right to rectification if any personal data we hold about you is inaccurate or incomplete.</li>
                <li>The right to erasure</li>
                <li>The right to restrict</li>
                <li>The right to data portability</li>
                <li>The right to object to us using your personal data for any particular purpose</li>
              </ul>

              <p className="text-base text-gray-700 md:text-lg mt-4">
              If you wish to exercise any of these rights please contact us. You may ask us to confirm what information we hold about you at any time, 
              and request us to modify, update or delete such information. We may ask you to verify your identity and for more information in connection with your request.
              </p>

              <p className="text-base text-gray-700 md:text-lg mt-4">
              If we provide you with access to the information we hold about you, we will not charge you for this unless your request is “manifestly unfounded or excessive”. 
              If you request further copies of this information from us, we may charge you a reasonable administrative cost where legally permissible. Where we are legally permitted 
              to do so, we may refuse your request. If we refuse your request we will always tell you the reasons for doing so.
              </p>

              
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
